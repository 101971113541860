const { HTTTP_URL, SOCKET_URL, WEB_ENV } = process.env;
class Vconsole {
  constructor() {
    this.fnsKey = ["log", "info", "warn", "debug", "error"];
    this.logs = [];
    this.init();
  }
  intercept(type, ...args) {
    this.logs.push({
      type,
      args: args,
    });
    this.renderItems();
  }
  listen() {
    for (let item of this.fnsKey) {
      const proxy = new Proxy(console[item], {
        apply: (fn, thisArg, args) => {
          // console.info("args==========", args);
          this.intercept(item, ...args);
          // 代理的目标函数,函数执行时的this的指向（默认指向全局，值为undefined）,代理函数被调用时的参数列表，
          return fn(...args);
        },

        construct: (fn, args, proxy) => {
          // 代理的目标函数，代理函数被调用时的参数列表，代理对象
          const result = new fn(...args);
          return result;
        },

        get: function (target, key, proxy) {
          // 代理的目标对象，代理目标的属性，代理对象(p)
          // console.info(
          //   "原目标对象：",
          //   target,
          //   "\n拦截访问的属性：" + key,
          //   "\n触发本次拦截的对象",
          //   proxy
          // ); // 访问属性时，触发拦截打印

          return Reflect.get(target, key);
        },
        set: function (target, key, value, proxy) {
          // console.info("数据试图被修改成", value, "已拦截此操作");
          const result = Reflect.set(target, key, Reflect.get(target, key)); // set,修改key属性的值，返回布尔值
          return result;
        },

        ownKeys: (target) => {
          // 原目标对象
          const result = Reflect.ownKeys(target);
          return result;
        },
      });
      this[item] = (...ags) => {
        return proxy(...ags);
      };
      window.console[item] = this[item];
    }
  }
  init() {
    this.listen();
    this.insertHtmlCss();
    this.events();
  }
  renderItems() {
    let items = document.getElementById("vc-scroller-items"); // 获取苹果li
    let html = "";
    for (let item of this.logs) {
      const { type, args } = item;
      html += `  <div class="vc-scroller-item" style="display: block; top: 0px;">
                      <div class="vc-log-row vc-log-log">
                        <div class="vc-log-content">
                           <i class="vc-log-val vc-log-val-string" style="">
                           ${type}:${args.join(" ")}
                          </i>
                          <i class="vc-log-val vc-log-val-undefined" style=""> </i>
                          </div>
                        <div class="vc-logrow-icon"><i class="vc-icon"> <svg class="vc-icon-copy" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M5.75 1a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-4.5zm.75 3V2.5h3V4h-3zm-2.874-.467a.75.75 0 00-.752-1.298A1.75 1.75 0 002 3.75v9.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-9.5a1.75 1.75 0 00-.874-1.515.75.75 0 10-.752 1.298.25.25 0 01.126.217v9.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.126-.217z">
                              </path>
                            </svg> </i></div>
                      </div>
           </div>`;
    }

    items.innerHTML = html;
    /*
    <div class="vc-scroller-items">
                 <div class="vc-scroller-item" style="display: block; top: 0px;">
                      <div class="vc-log-row vc-log-log">
                        <div class="vc-log-content"> <i class="vc-log-val vc-log-val-string" style="">vconsole===</i> <i class="vc-log-val vc-log-val-undefined" style="">undefined</i></div>
                        <div class="vc-logrow-icon"><i class="vc-icon"> <svg class="vc-icon-copy" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M5.75 1a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-4.5zm.75 3V2.5h3V4h-3zm-2.874-.467a.75.75 0 00-.752-1.298A1.75 1.75 0 002 3.75v9.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-9.5a1.75 1.75 0 00-.874-1.515.75.75 0 10-.752 1.298.25.25 0 01.126.217v9.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.126-.217z">
                              </path>
                            </svg> </i></div>
                      </div>
                    </div>
        </div>


 */
  }
  events() {
    const vcSwitch = document.getElementById("vc-switch");
    const vcMask = document.getElementById("vc-mask");
    const vcPanel = document.getElementById("vc-panel");
    const vcHide = document.getElementById("vc-hide");
    const clear = document.getElementById("clear");


    
    clear.onclick = () => {
      this.logs=[]
      this.renderItems();
    };
    vcSwitch.onclick = () => {
      vcMask.style.display = "block";
      vcPanel.style.display = "block";
    };
    vcHide.onclick = () => {
      vcMask.style.display = "none";
      vcPanel.style.display = "none";
    };
    vcMask.onclick = () => {
      vcMask.style.display = "none";
      vcPanel.style.display = "none";
    };

    // 获取手指初始坐标和盒子的原来位置
    var startX = 0;
    var startY = 0;
    // 获取盒子原来的位置
    var x = 0;
    var y = 0;
    let moveFlag = false;

    function down(e) {
      moveFlag = true;
      // 得到初始的手指坐标
      startX = ((e.targetTouches && e.targetTouches[0]) || e).clientX;
      startY = ((e.targetTouches && e.targetTouches[0]) || e).clientY;
      // 获取盒子坐标
      x = this.offsetLeft;
      y = this.offsetTop;
    }

    const move = (e) => {
      if (!moveFlag) {
        return false;
      }
      // 手指的移动距离= 手指移动之后的坐标 - 手指初始的坐标
      var moveX =
        ((e.targetTouches && e.targetTouches[0]) || e).clientX - startX;
      var moveY =
        ((e.targetTouches && e.targetTouches[0]) || e).clientY - startY;
      // 移动盒子，盒子原来的位置+手指移动的距离
      vcSwitch.style.left = x + moveX + "px";
      vcSwitch.style.top = y + moveY + "px";
      // 阻止屏幕滚动行为
      e.preventDefault();
    };
    const end = (event) => {
      moveFlag = false;
    };
    vcSwitch.ontouchstart = down;
    vcSwitch.ontouchmove = move;
    vcSwitch.ontouchend = end;
    vcSwitch.onmousedown = down;
    vcSwitch.onmousemove = move;
    vcSwitch.onmouseup = end;
    // vcSwitch.addEventListener("touchstart", down);
    // vcSwitch.addEventListener("touchmove", move);
    // //  绑定 touchend 事件
    // vcSwitch.addEventListener("touchend", end);

    // vcSwitch.addEventListener("mousedown", down);
    // //  绑定 touchend 事件
    // vcSwitch.addEventListener("mousemove", move);
    // vcSwitch.addEventListener("mouseup", end);
  }
  insertHtmlCss() {
    if (!document.getElementById("console-style")) {
      const style = document.createElement("style");
      style.id = "console-style";
      style.type = "text/css";
      style.innerHTML = `  
#console-html {
--VC-BG-0: #ededed;
--VC-BG-1: #f7f7f7;
--VC-BG-2: #fff;
--VC-BG-3: #f7f7f7;
--VC-BG-4: #4c4c4c;
--VC-BG-5: #fff;
--VC-BG-6: rgba(0, 0, 0, 0.1);
--VC-FG-0: rgba(0, 0, 0, 0.9);
--VC-FG-HALF: rgba(0, 0, 0, 0.9);
--VC-FG-1: rgba(0, 0, 0, 0.5);
--VC-FG-2: rgba(0, 0, 0, 0.3);
--VC-FG-3: rgba(0, 0, 0, 0.1);
--VC-RED: #fa5151;
--VC-ORANGE: #fa9d3b;
--VC-YELLOW: #ffc300;
--VC-GREEN: #91d300;
--VC-LIGHTGREEN: #95ec69;
--VC-BRAND: #07c160;
--VC-BLUE: #10aeff;
--VC-INDIGO: #1485ee;
--VC-PURPLE: #6467f0;
--VC-LINK: #576b95;
--VC-TEXTGREEN: #06ae56;
--VC-FG: black;
--VC-BG: white;
--VC-BG-COLOR-ACTIVE: #ececec;
--VC-WARN-BG: #fff3cc;
--VC-WARN-BORDER: #ffe799;
--VC-ERROR-BG: #fedcdc;
--VC-ERROR-BORDER: #fdb9b9;
--VC-DOM-TAG-NAME-COLOR: #881280;
--VC-DOM-ATTRIBUTE-NAME-COLOR: #994500;
--VC-DOM-ATTRIBUTE-VALUE-COLOR: #1a1aa6;
--VC-CODE-KEY-FG: #881391;
--VC-CODE-PRIVATE-KEY-FG: #cfa1d3;
--VC-CODE-FUNC-FG: #0d22aa;
--VC-CODE-NUMBER-FG: #1c00cf;
--VC-CODE-STR-FG: #c41a16;
--VC-CODE-NULL-FG: #808080;
color: var(--VC-FG-0);
font-size: 13px;
font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
-webkit-user-select: auto;
/* global */
}

#console-html .vc-max-height {
max-height: 19.23076923em;
}

#console-html .vc-max-height-line {
max-height: 6.30769231em;
}

#console-html .vc-min-height {
min-height: 3.07692308em;
}

#console-html dd,
#console-html dl,
#console-html pre {
margin: 0;
}

#console-html pre {
white-space: pre-wrap;
}

#console-html i {
font-style: normal;
}

.vc-table {
height: 100%;
}

.vc-table .vc-table-row {
line-height: 1.5;
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-webkit-flex-direction: row;
-moz-box-orient: horizontal;
-moz-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
overflow: hidden;
border-bottom: 1px solid var(--VC-FG-3);
}

.vc-table .vc-table-row.vc-left-border {
border-left: 1px solid var(--VC-FG-3);
}

.vc-table .vc-table-row-icon {
margin-left: 4px;
}

.vc-table .vc-table-col {
-webkit-box-flex: 1;
-webkit-flex: 1;
-moz-box-flex: 1;
-ms-flex: 1;
flex: 1;
padding: 0.23076923em 0.30769231em;
border-left: 1px solid var(--VC-FG-3);
overflow: auto;
}

.vc-table .vc-table-col:first-child {
border: none;
}

.vc-table .vc-table-col-value {
white-space: pre-wrap;
word-break: break-word;
/*white-space: nowrap;
text-overflow: ellipsis;*/
-webkit-overflow-scrolling: touch;
}

.vc-table .vc-small .vc-table-col {
padding: 0 0.30769231em;
font-size: 0.92307692em;
}

.vc-table .vc-table-col-2 {
-webkit-box-flex: 2;
-webkit-flex: 2;
-moz-box-flex: 2;
-ms-flex: 2;
flex: 2;
}

.vc-table .vc-table-col-3 {
-webkit-box-flex: 3;
-webkit-flex: 3;
-moz-box-flex: 3;
-ms-flex: 3;
flex: 3;
}

.vc-table .vc-table-col-4 {
-webkit-box-flex: 4;
-webkit-flex: 4;
-moz-box-flex: 4;
-ms-flex: 4;
flex: 4;
}

.vc-table .vc-table-col-5 {
-webkit-box-flex: 5;
-webkit-flex: 5;
-moz-box-flex: 5;
-ms-flex: 5;
flex: 5;
}

.vc-table .vc-table-col-6 {
-webkit-box-flex: 6;
-webkit-flex: 6;
-moz-box-flex: 6;
-ms-flex: 6;
flex: 6;
}

.vc-table .vc-table-row-error {
border-color: var(--VC-ERROR-BORDER);
background-color: var(--VC-ERROR-BG);
}

.vc-table .vc-table-row-error .vc-table-col {
color: var(--VC-RED);
border-color: var(--VC-ERROR-BORDER);
}

.vc-table .vc-table-col-title {
font-weight: bold;
}

.vc-table .vc-table-action {
display: flex;
justify-content: space-evenly;
}

.vc-table .vc-table-action .vc-icon {
flex: 1;
text-align: center;
display: block;
}

.vc-table .vc-table-action .vc-icon:hover {
background: var(--VC-BG-3);
}

.vc-table .vc-table-action .vc-icon:active {
background: var(--VC-BG-1);
}

.vc-table .vc-table-input {
width: 100%;
border: none;
color: var(--VC-FG-0);
background-color: var(--VC-BG-6);
height: 3.53846154em;
}

.vc-table .vc-table-input:focus {
background-color: var(--VC-FG-2);
}

@media (prefers-color-scheme: dark) {
#console-html:not([data-theme="light"]) {
  --VC-BG-0: #191919;
  --VC-BG-1: #1f1f1f;
  --VC-BG-2: #232323;
  --VC-BG-3: #2f2f2f;
  --VC-BG-4: #606060;
  --VC-BG-5: #2c2c2c;
  --VC-BG-6: rgba(255, 255, 255, 0.2);
  --VC-FG-0: rgba(255, 255, 255, 0.8);
  --VC-FG-HALF: rgba(255, 255, 255, 0.6);
  --VC-FG-1: rgba(255, 255, 255, 0.5);
  --VC-FG-2: rgba(255, 255, 255, 0.3);
  --VC-FG-3: rgba(255, 255, 255, 0.05);
  --VC-RED: #fa5151;
  --VC-ORANGE: #c87d2f;
  --VC-YELLOW: #cc9c00;
  --VC-GREEN: #74a800;
  --VC-LIGHTGREEN: #28b561;
  --VC-BRAND: #07c160;
  --VC-BLUE: #10aeff;
  --VC-INDIGO: #1196ff;
  --VC-PURPLE: #8183ff;
  --VC-LINK: #7d90a9;
  --VC-TEXTGREEN: #259c5c;
  --VC-FG: white;
  --VC-BG: black;
  --VC-BG-COLOR-ACTIVE: #282828;
  --VC-WARN-BG: #332700;
  --VC-WARN-BORDER: #664e00;
  --VC-ERROR-BG: #321010;
  --VC-ERROR-BORDER: #642020;
  --VC-DOM-TAG-NAME-COLOR: #5db0d7;
  --VC-DOM-ATTRIBUTE-NAME-COLOR: #9bbbdc;
  --VC-DOM-ATTRIBUTE-VALUE-COLOR: #f29766;
  --VC-CODE-KEY-FG: #e36eec;
  --VC-CODE-PRIVATE-KEY-FG: #f4c5f7;
  --VC-CODE-FUNC-FG: #556af2;
  --VC-CODE-NUMBER-FG: #9980ff;
  --VC-CODE-STR-FG: #e93f3b;
  --VC-CODE-NULL-FG: #808080;
}
}

#console-html[data-theme="dark"] {
--VC-BG-0: #191919;
--VC-BG-1: #1f1f1f;
--VC-BG-2: #232323;
--VC-BG-3: #2f2f2f;
--VC-BG-4: #606060;
--VC-BG-5: #2c2c2c;
--VC-BG-6: rgba(255, 255, 255, 0.2);
--VC-FG-0: rgba(255, 255, 255, 0.8);
--VC-FG-HALF: rgba(255, 255, 255, 0.6);
--VC-FG-1: rgba(255, 255, 255, 0.5);
--VC-FG-2: rgba(255, 255, 255, 0.3);
--VC-FG-3: rgba(255, 255, 255, 0.05);
--VC-RED: #fa5151;
--VC-ORANGE: #c87d2f;
--VC-YELLOW: #cc9c00;
--VC-GREEN: #74a800;
--VC-LIGHTGREEN: #28b561;
--VC-BRAND: #07c160;
--VC-BLUE: #10aeff;
--VC-INDIGO: #1196ff;
--VC-PURPLE: #8183ff;
--VC-LINK: #7d90a9;
--VC-TEXTGREEN: #259c5c;
--VC-FG: white;
--VC-BG: black;
--VC-BG-COLOR-ACTIVE: #282828;
--VC-WARN-BG: #332700;
--VC-WARN-BORDER: #664e00;
--VC-ERROR-BG: #321010;
--VC-ERROR-BORDER: #642020;
--VC-DOM-TAG-NAME-COLOR: #5db0d7;
--VC-DOM-ATTRIBUTE-NAME-COLOR: #9bbbdc;
--VC-DOM-ATTRIBUTE-VALUE-COLOR: #f29766;
--VC-CODE-KEY-FG: #e36eec;
--VC-CODE-PRIVATE-KEY-FG: #f4c5f7;
--VC-CODE-FUNC-FG: #556af2;
--VC-CODE-NUMBER-FG: #9980ff;
--VC-CODE-STR-FG: #e93f3b;
--VC-CODE-NULL-FG: #808080;
}

.vc-tabbar {
border-bottom: 1px solid var(--VC-FG-3);
overflow-x: auto;
height: 3em;
width: auto;
white-space: nowrap;
}

.vc-tabbar .vc-tab {
display: inline-block;
line-height: 3em;
padding: 0 1.15384615em;
border-right: 1px solid var(--VC-FG-3);
text-decoration: none;
color: var(--VC-FG-0);
-webkit-tap-highlight-color: transparent;
-webkit-touch-callout: none;
}

.vc-tabbar .vc-tab:active {
background-color: rgba(0, 0, 0, 0.15);
}

.vc-tabbar .vc-tab.vc-actived {
background-color: var(--VC-BG-1);
}

.vc-toolbar {
border-top: 1px solid var(--VC-FG-3);
line-height: 3em;
position: absolute;
left: 0;
right: 0;
bottom: 0;
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-webkit-flex-direction: row;
-moz-box-orient: horizontal;
-moz-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
}

.vc-toolbar .vc-tool {
display: none;
font-style: normal;
text-decoration: none;
color: var(--VC-FG-0);
width: 50%;
-webkit-box-flex: 1;
-webkit-flex: 1;
-moz-box-flex: 1;
-ms-flex: 1;
flex: 1;
text-align: center;
position: relative;
-webkit-touch-callout: none;
}

.vc-toolbar .vc-tool.vc-toggle,
.vc-toolbar .vc-tool.vc-global-tool {
display: block;
}

.vc-toolbar .vc-tool:active {
background-color: rgba(0, 0, 0, 0.15);
}

.vc-toolbar .vc-tool:after {
content: " ";
position: absolute;
top: 0.53846154em;
bottom: 0.53846154em;
right: 0;
border-left: 1px solid var(--VC-FG-3);
}

.vc-toolbar .vc-tool-last:after {
border: none;
}

.vc-topbar {
background-color: var(--VC-BG-1);
display: -webkit-box;
display: -webkit-flex;
display: -moz-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-webkit-flex-direction: row;
-moz-box-orient: horizontal;
-moz-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
width: 100%;
}

.vc-topbar .vc-toptab {
display: none;
-webkit-box-flex: 1;
-webkit-flex: 1;
-moz-box-flex: 1;
-ms-flex: 1;
flex: 1;
line-height: 2.30769231em;
padding: 0 1.15384615em;
border-bottom: 1px solid var(--VC-FG-3);
text-decoration: none;
text-align: center;
color: var(--VC-FG-0);
-webkit-tap-highlight-color: transparent;
-webkit-touch-callout: none;
}

.vc-topbar .vc-toptab.vc-toggle {
display: block;
}

.vc-topbar .vc-toptab:active {
background-color: rgba(0, 0, 0, 0.15);
}

.vc-topbar .vc-toptab.vc-actived {
border-bottom: 1px solid var(--VC-INDIGO);
}

.vc-mask {
display: none;
position: fixed;
top:0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0);
z-index: 10001;
-webkit-transition: background 0.3s;
transition: background 0.3s;
-webkit-tap-highlight-color: transparent;
overflow-y: scroll;
}

.vc-panel {
display: none;
position: fixed;
min-height: 85%;
left: 0;
right: 0;
bottom: -100%;
z-index: 10002;
background-color: var(--VC-BG-0);
transition: bottom 0.3s;
}



.vc-toggle .vc-mask {
background: rgba(0, 0, 0, 0.6);
}

.vc-toggle .vc-panel {
bottom: 0;
}

.vc-content {
background-color: var(--VC-BG-2);
overflow-x: hidden;
overflow-y: auto;
position: absolute;
top: 3.07692308em;
left: 0;
right: 0;
bottom: 3.07692308em;
-webkit-overflow-scrolling: touch;
margin-bottom: constant(safe-area-inset-bottom);
margin-bottom: env(safe-area-inset-bottom);
}

.vc-content.vc-has-topbar {
top: 5.46153846em;
}

.vc-plugin-box {
display: none;
position: relative;
min-height: 100%;
}

.vc-plugin-box.vc-fixed-height {
height: 100%;
}

.vc-plugin-box.vc-actived {
display: block;
}

.vc-plugin-content {
display: flex;
width: 100%;
height: 100%;
overflow-y: auto;
flex-direction: column;
-webkit-tap-highlight-color: transparent;
}

.vc-plugin-content:empty:before {
content: "Empty";
color: var(--VC-FG-1);
position: absolute;
top: 45%;
left: 0;
right: 0;
bottom: 0;
font-size: 1.15384615em;
text-align: center;
}

.vc-plugin-empty {
color: var(--VC-FG-1);
font-size: 1.15384615em;
height: 100%;
width: 100%;
padding: 1.15384615em 0;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
}

@supports (bottom: constant(safe-area-inset-bottom)) or
(bottom: env(safe-area-inset-bottom)) {
.vc-toolbar,
.vc-switch {
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
}
}

.vc-icon {
word-break: normal;
white-space: normal;
overflow: visible;
}

.vc-icon svg {
fill: var(--VC-FG-2);
height: 1em;
width: 1em;
vertical-align: -0.11em;
}

.vc-icon .vc-icon-delete {
vertical-align: -0.11em;
}

.vc-icon .vc-icon-copy {
height: 1.1em;
width: 1.1em;
vertical-align: -0.16em;
}

.vc-icon .vc-icon-suc {
fill: var(--VC-TEXTGREEN);
height: 1.1em;
width: 1.1em;
vertical-align: -0.16em;
}

.vc-log-row {
margin: 0;
padding: 0.46153846em 0.61538462em;
overflow: hidden;
line-height: 1.3;
border-bottom: 1px solid var(--VC-FG-3);
word-break: break-word;
position: relative;
display: flex;
}

.vc-log-info {
color: var(--VC-PURPLE);
}

.vc-log-debug {
color: var(--VC-YELLOW);
}

.vc-log-warn {
color: var(--VC-ORANGE);
border-color: var(--VC-WARN-BORDER);
background-color: var(--VC-WARN-BG);
}

.vc-log-error {
color: var(--VC-RED);
border-color: var(--VC-ERROR-BORDER);
background-color: var(--VC-ERROR-BG);
}

.vc-logrow-icon {
margin-left: auto;
}

.vc-log-padding {
width: 1.53846154em;
border-left: 1px solid var(--VC-FG-3);
}

.vc-log-group .vc-log-content {
font-weight: bold;
}

.vc-log-group-toggle {
padding-left: 0.76923077em;
}

.vc-log-group-toggle {
display: block;
font-style: italic;
padding-left: 0.76923077em;
position: relative;
}

.vc-log-group-toggle:active {
background-color: var(--VC-BG-COLOR-ACTIVE);
}

.vc-log-group > .vc-log-group-toggle::before {
content: "";
position: absolute;
top: 0.30769231em;
left: 0.15384615em;
width: 0;
height: 0;
border: transparent solid 0.30769231em;
border-left-color: var(--VC-FG-1);
}

.vc-log-group.vc-toggle > .vc-log-group-toggle::before {
top: 0.46153846em;
left: 0;
border-top-color: var(--VC-FG-1);
border-left-color: transparent;
}

.vc-log-time {
width: 6.15384615em;
color: #777;
}

.vc-log-repeat i {
margin-right: 0.30769231em;
padding: 0 6.5px;
color: #d7e0ef;
background-color: #42597f;
border-radius: 8.66666667px;
}

.vc-log-error .vc-log-repeat i {
color: #901818;
background-color: var(--VC-RED);
}

.vc-log-warn .vc-log-repeat i {
color: #987d20;
background-color: #f4bd02;
}

.vc-log-content {
flex: 1;
}

.vc-log-input,
.vc-log-output {
padding-left: 0.92307692em;
}

.vc-log-input:before,
.vc-log-output:before {
content: "›";
position: absolute;
top: 0.15384615em;
left: 0;
font-size: 1.23076923em;
color: #6a5acd;
}

.vc-log-output:before {
content: "‹";
}

.vc-cmd {
height: 3.07692308em;
border-top: 1px solid var(--VC-FG-3);
display: flex;
flex-direction: row;
}

.vc-cmd.vc-filter {
bottom: 0;
}

.vc-cmd-input-wrap {
display: flex;
align-items: center;
flex: 1;
position: relative;
height: 2.15384615em;
padding: 0.46153846em 0.61538462em;
}

.vc-cmd-input {
width: 100%;
border: none;
resize: none;
outline: none;
padding: 0;
font-size: 0.92307692em;
background-color: transparent;
color: var(--VC-FG-0);
}

.vc-cmd-input::-webkit-input-placeholder {
line-height: 2.15384615em;
}

.vc-cmd-btn {
width: 3.07692308em;
border: none;
background-color: var(--VC-BG-0);
color: var(--VC-FG-0);
outline: none;
-webkit-touch-callout: none;
font-size: 1em;
}

.vc-cmd-clear-btn {
flex: 1 3.07692308em;
text-align: center;
line-height: 3.07692308em;
}

.vc-cmd-btn:active,
.vc-cmd-clear-btn:active {
background-color: var(--VC-BG-COLOR-ACTIVE);
}

.vc-cmd-prompted {
position: absolute;
left: 0.46153846em;
right: 0.46153846em;
background-color: var(--VC-BG-3);
border: 1px solid var(--VC-FG-3);
overflow-x: scroll;
display: none;
}

.vc-cmd-prompted li {
list-style: none;
line-height: 30px;
padding: 0 0.46153846em;
border-bottom: 1px solid var(--VC-FG-3);
}

.vc-cmd-prompted li:active {
background-color: var(--VC-BG-COLOR-ACTIVE);
}

.vc-cmd-prompted-hide {
text-align: center;
}
.vc-scroller-viewport {
flex: 1;
}
.vc-scroller-contents {
height: 100%;
display: -webkit-box;
display: -moz-box;
display: -ms-box;
-webkit-box-orient: vertical;
-moz-box-orient: vertical;
-ms-box-orient: vertical;
}
.vc-scroller-items {
flex: 1;
-webkit-box-flex: 1;
-moz-box-flex: 1;
-ms-box-flex: 1;
-webkit-box-flex-group: 1;
-moz-box-flex-group: 1;
-ms-box-flex-group: 1;
}

.vc-switch {
color: #fff;
background-color: var(--VC-BRAND);
z-index: 10000;
border-radius: 0.30769231em;
box-shadow: 0 0 0.61538462em rgba(0, 0, 0, 0.4);
position: fixed;
height: 40px;
width: 100px;
line-height: 40px;
text-align: center;
left: calc(100vw - 200px);
top: calc(100vh - 200px);
cursor: pointer;
}
`;


      document.head.appendChild(style);
    }

    if (!document.getElementById("console-html")) {
      const html = document.createElement("div");
      html.id = "console-html";
      html.className = "vc-toggle";
      html.innerHTML = this.initHtml();
      document.body.appendChild(html);
    }
  }
  initHtml() {
    let html = `<div id="vc-switch" class="vc-switch"  >vConsole</div>
      <div class="vc-mask"   id="vc-mask" ></div>
      <div class="vc-panel"  id="vc-panel" >
        <div class="vc-tabbar"><a class="vc-tab vc-actived" id="__vc_tab_default">Log</a><a class="vc-tab"
            id="__vc_tab_system">System</a><a class="vc-tab" id="__vc_tab_network">Network</a><a class="vc-tab"
            id="__vc_tab_element">Element</a><a class="vc-tab" id="__vc_tab_storage">Storage</a></div>
        <div class="vc-topbar"><i class="vc-toptab vc-topbar-default vc-actived vc-toggle">All</i><i
            class="vc-toptab vc-topbar-default vc-toggle">Log</i><i
            class="vc-toptab vc-topbar-default vc-toggle">Info</i><i
            class="vc-toptab vc-topbar-default vc-toggle">Warn</i><i
            class="vc-toptab vc-topbar-default vc-toggle">Error</i><i
            class="vc-toptab vc-topbar-system vc-actived">All</i><i class="vc-toptab vc-topbar-system">Log</i><i
            class="vc-toptab vc-topbar-system">Info</i><i class="vc-toptab vc-topbar-system">Warn</i><i
            class="vc-toptab vc-topbar-system">Error</i><i class="vc-toptab vc-topbar-storage vc-actived">Cookies</i><i
            class="vc-toptab vc-topbar-storage">LocalStorage</i><i class="vc-toptab vc-topbar-storage">SessionStorage</i>
        </div>
        <div class="vc-content vc-has-topbar">
          <div id="__vc_plug_default" class="vc-plugin-box vc-fixed-height vc-actived">
            <div class="vc-plugin-content vc-logs-has-cmd">
              <div class="vc-scroller-viewport">
                <div class="vc-scroller-contents"  >

                  <!-- 这个没撑开 -->
                   <div class="vc-scroller-items"  id="vc-scroller-items" >
                    <div class="vc-scroller-item" style="display: block; top: 0px;">
                      <div class="vc-log-row vc-log-log">
                        <div class="vc-log-content"> <i class="vc-log-val vc-log-val-string" style="">vconsole===</i> <i
                            class="vc-log-val vc-log-val-undefined" style="">undefined</i></div>
                        <div class="vc-logrow-icon"><i class="vc-icon"> <svg class="vc-icon-copy" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M5.75 1a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-4.5zm.75 3V2.5h3V4h-3zm-2.874-.467a.75.75 0 00-.752-1.298A1.75 1.75 0 002 3.75v9.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-9.5a1.75 1.75 0 00-.874-1.515.75.75 0 10-.752 1.298.25.25 0 01.126.217v9.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.126-.217z">
                              </path>
                            </svg> </i></div>
                      </div>
                    </div>
                    <div class="vc-scroller-item" style="display: block; top: 29.8906px;">
                      <div class="vc-log-row vc-log-log">
                        <div class="vc-log-content"> <i class="vc-log-val vc-log-val-string"
                            style="">global-state-cache-read: 2ms</i></div>
                        <div class="vc-logrow-icon"><i class="vc-icon"> <svg class="vc-icon-copy" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M5.75 1a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-4.5zm.75 3V2.5h3V4h-3zm-2.874-.467a.75.75 0 00-.752-1.298A1.75 1.75 0 002 3.75v9.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-9.5a1.75 1.75 0 00-.874-1.515.75.75 0 10-.752 1.298.25.25 0 01.126.217v9.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.126-.217z">
                              </path>
                            </svg> </i></div>
                      </div>
                    </div>
                  </div>
                  <div class="vc-scroller-footer">
                    <form class="vc-cmd">
                      <ul class="vc-cmd-prompted" style="">
                        <li>No Prompted</li>
                      </ul>
                      <div class="vc-cmd-input-wrap"><textarea class="vc-cmd-input" placeholder="command..."></textarea>
                      </div> <button class="vc-cmd-btn" type="submit">OK</button>
                    </form>
                    <form class="vc-cmd vc-filter">
                      <ul class="vc-cmd-prompted"></ul>
                      <div class="vc-cmd-input-wrap"><textarea class="vc-cmd-input" placeholder="filter..."></textarea>
                      </div> <button class="vc-cmd-btn" type="submit">Filter</button>
                    </form>
                  </div>
                </div>
                <div class="vc-scroller-scrollbar-track" style="display: none;">
                  <div class="vc-scroller-scrollbar-thumb" style="height: 100%; top: 0%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div id="__vc_plug_system" class="vc-plugin-box vc-fixed-height">
            <div class="vc-plugin-content">
              <div class="vc-scroller-viewport">
                <div class="vc-scroller-contents" style="transform: translateY(-270px) translateZ(0px);">
                  <div class="vc-scroller-items" style="height: 270px;"></div>
                  <div class="vc-scroller-footer"></div>
                </div>
                <div class="vc-scroller-scrollbar-track" style="display: block;">
                  <div class="vc-scroller-scrollbar-thumb" style="height: 0%; top: 100%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div id="__vc_plug_network" class="vc-plugin-box vc-fixed-height">
            <div class="vc-table">
              <div class="vc-plugin-content">
                <div class="vc-scroller-viewport">
                  <div class="vc-scroller-contents" style="transform: translateY(-30px) translateZ(0px);">
                    <div class="vc-scroller-header">
                      <dl class="vc-table-row">
                        <dd class="vc-table-col vc-table-col-4">Name (1)</dd>
                        <dd class="vc-table-col">Method</dd>
                        <dd class="vc-table-col">Status</dd>
                        <dd class="vc-table-col">Time</dd>
                      </dl>
                    </div>
                    <div class="vc-scroller-items" style="height: 30px;"></div>
                  </div>
                  <div class="vc-scroller-scrollbar-track" style="display: block;">
                    <div class="vc-scroller-scrollbar-thumb" style="height: 0%; top: 100%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="__vc_plug_element" class="vc-plugin-box">
            <div class="vc-plugin-content"></div>
          </div>
          <div id="__vc_plug_storage" class="vc-plugin-box">
            <div class="vc-table">
              <div class="vc-table-row">
                <div class="vc-table-col">Key</div>
                <div class="vc-table-col vc-table-col-2">Value</div>
                <div class="vc-table-col vc-table-col-1 vc-table-action"></div>
              </div>
              <div class="vc-plugin-empty">Empty</div>
            </div>
          </div>
        </div>
        <div class="vc-toolbar"><i class="vc-tool vc-tool-default vc-toggle" id='clear'>Clear</i><i
            class="vc-tool vc-tool-default vc-toggle">Top</i><i class="vc-tool vc-tool-default vc-toggle">Bottom</i><i
            class="vc-tool vc-tool-system">Clear</i><i class="vc-tool vc-tool-system">Top</i><i
            class="vc-tool vc-tool-system">Bottom</i><i class="vc-tool vc-tool-network">Clear</i><i
            class="vc-tool vc-tool-element">Expand</i><i class="vc-tool vc-tool-element">Collapse</i><i
            class="vc-tool vc-tool-storage">Add</i><i class="vc-tool vc-tool-storage">Refresh</i><i
            class="vc-tool vc-tool-storage">Clear</i> <i class="vc-tool vc-global-tool vc-tool-last vc-hide" id='vc-hide'>Hide</i>
        </div>
      </div>

  `;

    return html;
  }
}
let vconsole;
if (WEB_ENV == "development" || WEB_ENV == "testing") {
  vconsole = new Vconsole();
} else {
  vconsole = console;
}

export default vconsole;
