import "./util/handleError";
import "./util/setupServiceWorker";
import "./global/init";

import React from "./lib/teact/teact";
import TeactDOM from "./lib/teact/teact-dom";
import { getActions, getGlobal } from "./global";
import { reconnect } from "./api/gramjs";
import {
  DEBUG,
  MULTITAB_LOCALSTORAGE_KEY,
  STRICTERDOM_ENABLED,
} from "./config";
import { enableStrict, requestMutation } from "./lib/fasterdom/fasterdom";
import { selectTabState } from "./global/selectors";
import { betterView } from "./util/betterView";
import {
  establishMultitabRole,
  subscribeToMasterChange,
} from "./util/establishMultitabRole";
import {
  requestGlobal,
  subscribeToMultitabBroadcastChannel,
} from "./util/multitab";
import { checkAndAssignPermanentWebVersion } from "./util/permanentWebVersion";
import { onBeforeUnload } from "./util/schedulers";
import updateWebmanifest from "./util/updateWebmanifest";
import { IS_MULTITAB_SUPPORTED } from "./util/windowEnvironment";

import App from "./components/App";
import XMLHttpRequest from "src/lib/XMLHttpRequest.js";
import Loading from "src/components/ui/Loading.tsx";
import "./assets/fonts/roboto.css";
import "./styles/index.scss";
 

const { HTTTP_URL, SOCKET_URL, WEB_ENV } = process.env;
if (STRICTERDOM_ENABLED) {
  enableStrict();
}

const getAddress = async () => {
  let address = localStorage.getItem("address");

  // if (!address) {
  address = await new Promise((reslove, reject) => {
    new XMLHttpRequest().xhRequest({
      url: HTTTP_URL,
      method: "GET",
      success: async (res) => {
        const { backupDomains: list } = res;
        let $data = list.map((item) => {
          return `${item}:443`;
        });

        localStorage.setItem(
          "address",
          JSON.stringify(
            WEB_ENV == "development"
              ? [].concat($data).filter((item, index, self) => {
                  return self.indexOf(item) === index;
                })
              : $data
          )
        );
        reslove($data);
      },
      error: (error) => {
        localStorage.setItem("address", JSON.stringify([SOCKET_URL]));
        reslove(error);
      },
    });
  });
  // } else {
  //   address = (JSON.parse(address))
  // }
  return address;
};

const REFRESH_TIME = 180;
const RECONNECT_TIME = 20;
const RECONNECT_DIRECT_TIME = 10;

const checkVisiblityChange = () => {
  document.addEventListener('visibilitychange', function() {
    let hiddenTime;

    if(document.visibilityState == 'hidden') {
      //记录页面隐藏时间
      hiddenTime = new Date().getTime();
      localStorage.setItem('hiddenTime', hiddenTime.toString());
      console.log('页面隐藏：', hiddenTime);
    } else {
      hiddenTime = hiddenTime || Number(localStorage.getItem('hiddenTime'))
      let visibleTime = new Date().getTime();
      
      if((visibleTime - hiddenTime) / 1000 > REFRESH_TIME){	 // 页面再次可见的时间-隐藏时间 > 180S, 刷新页面
          window.location.reload();
      } else if((visibleTime - hiddenTime) / 1000 > RECONNECT_TIME){	// 页面再次可见的时间-隐藏时间 > 20S, 断开重连	
        try {
          console.log(`页面退到后台超过${RECONNECT_TIME}s，触发重连`)
          // init();
          reconnect(500, 5, true);
        } catch (error) {
          // window.location.reload();
        }
      } else if((visibleTime - hiddenTime) / 1000 > RECONNECT_DIRECT_TIME){	// 页面再次可见的时间-隐藏时间 > 10S, 重连	
        try {
          console.log(`页面退到后台超过${RECONNECT_TIME}s，触发重连`)
          // init();
          reconnect(500, 5, false);
        } catch (error) {
          // window.location.reload();
        }
      } else{
         console.log('还没有到断开的时间')
      }
    }
  })
};

const removeLoadingScreen = () => {
  const loadingScreen = document.getElementById("app-loading");
  if (loadingScreen) {
    loadingScreen.style.opacity = "0"; // 添加淡出效果
    setTimeout(() => loadingScreen.remove(), 500); // 完全移除
  }
};

async function init() {
  await getAddress();

  if (!(window as any).isCompatTestPassed) return;

  checkVisiblityChange();

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();

    await requestGlobal(APP_VERSION);

    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, "1");

    onBeforeUnload(() => {
      const global = getGlobal();

      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  getActions().initShared();

  // 初始化
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  if (IS_MULTITAB_SUPPORTED) {
    establishMultitabRole();
    subscribeToMasterChange((isMasterTab) => {
      //1
      getActions().switchMultitabRole(
        { isMasterTab },
        { forceSyncOnIOs: true }
      );
    });
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> START INITIAL RENDER");
  }

  // 渲染
  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(<App />, document.getElementById("root")!);
    // removeLoadingScreen();

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> FINISH INITIAL RENDER");
  }

  if (DEBUG) {
    document.addEventListener("dblclick", () => {
      // eslint-disable-next-line no-console
      console.warn("TAB STATE", selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn("GLOBAL STATE", getGlobal());
    });
  }
}

init();

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
