import { getGlobal } from '../global';

// hello9链接
export const hello9Link = (url: string | undefined) => {
    const global = getGlobal();
    if (url && global.currentUserId) {
        try {
        const hello9Url = new URL(url);
        if (hello9Url.origin === 'https://hello9.org' || hello9Url.origin === 'http://123.60.142.171')  {
            hello9Url.searchParams.set('code', global.currentUserId);
            return hello9Url.toString();
        }
        } catch (e) {
        console.error(e);
        }
    }
    return url || ''
}
