import type { FC } from "../../lib/teact/teact";
import React, {
  memo,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "../../lib/teact/teact";
import { getActions, withGlobal } from "../../global";
import type { GlobalState } from "../../global/types";
import { pick } from "../../util/iteratees";
import { IS_TOUCH_ENV } from "../../util/windowEnvironment";
import InputText from "../ui/InputText";
import Button from "../ui/Button";
import Divider from "../ui/Divider";
import Link from "../ui/Link";
import useLang from "../../hooks/useLang";
import { callApi } from "src/api/gramjs";
import InstallAppDrawer from "../common/installAppDrawer";
import styles from "./Register.module.scss";
import LogOut from "./LogOut";

// message

type StateProps = Pick<
  GlobalState,
  "authPhoneNumber" | "authIsCodeViaApp" | "authIsLoading" | "authError"
>;

const Login: FC<StateProps> = ({
  authPhoneNumber,
  authIsCodeViaApp,
  authIsLoading,
  authError,
  authorizationLogOutFlage,
  connectionState,
}) => {
  const { returnToAuthRegister, clearAuthError, provideAccountPassword } =
    getActions();
  const [accountNum, setAccountNum] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState("");

  const lang = useLang();
  // eslint-disable-next-line no-null/no-null
  const inputRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const onAccountNumChange = useCallback(
    (e) => {
      clearAuthError();
      const { currentTarget: target } = e;
      target.value = target.value.replace(/[^0-9.]/g, '')
      setAccountNum(target.value);
    },
    [accountNum]
  );
  const onPassWordChange = useCallback(
    (e) => {
      const { currentTarget: target } = e;
      setError("");
      clearAuthError();
      setPassword(target.value);
    },
    [password]
  );

  const habdleReturnToAuthRegister = useCallback(() => {
    returnToAuthRegister();
  }, [returnToAuthRegister]);

  useEffect(() => {
    // connectionStateConnecting
    // connectionStateReady
    // connectionStateBroken
    console.log("connectionState==========", connectionState);
  }, [connectionState]);

  useEffect(() => {
    localStorage.setItem('IS_REGISTER', "false")
  }, [])

  const handleOk = () => {
    if (password.length < 8) {
      setError(lang("密码长度必须至少为八个字符"));
      return;
    }
    // clearAuthError
    provideAccountPassword({
      type: "login",
      account: accountNum,
      password,
    });
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleOk();
    }
  };
  console.log("authorizationLogOutFlage==", authorizationLogOutFlage);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && getComputedStyle(inputRef.current).backgroundColor !== "rgb(255, 255, 255)" && inputRef.current?.parentElement?.classList.add('touched')
      passwordRef.current && getComputedStyle(passwordRef.current).backgroundColor !== "rgb(255, 255, 255)" && passwordRef.current?.parentElement?.classList.add('touched')
    }, 1000);
  }, []);
  return (
    <div className={styles.RegistrationPage}>
      <LogOut
        isOpen={authorizationLogOutFlage}
        onCancel={() => {
          // setLogOutOpen(false);
        }}
      />
      <div className={styles.pageTitle}>{lang("欢迎登录")}</div>
      <div className={styles.pageTips}>6 is easy</div>
      <InputText
        ref={inputRef}
        id="accountNumInput"
        label={lang("账户")}
        onInput={onAccountNumChange}
        value={accountNum}
        error={authError && lang(authError)}
        onKeyDown={handleEnterKey}
        autoComplete="off"
        inputMode="numeric"
      />
      <InputText
        ref={passwordRef}
        id="passWordInput"
        label={lang("密码")}
        type="password"
        onInput={onPassWordChange}
        value={password}
        error={error}
        onKeyDown={handleEnterKey}
        autoComplete="off"
      />
      <Button
        onClick={handleOk}
        // isLoading={true}
        isLoadingText={lang("登录")}
        className={styles.closeButton}
        disabled={connectionState != "connectionStateReady"}
      >
        {lang("登录")}
      </Button>

      {connectionState != "connectionStateReady" ? (
        <div className="login-loading-box">
          <span className="login-loading-text">
            {lang("网络正在连接，请稍后")}
          </span>
          <div className={`Loading interactive  login-loading`}>
            <div className="Spinner yellow with-background bg-light">
              <div className="Spinner__inner"></div>
            </div>
          </div>
        </div>
      ) : null}

      <Divider style="margin: 1.5rem 0;">or</Divider>
      <div className={styles.loginTips}>
        <span>{lang("还没有账号？")}</span>
        <Link isPrimary onClick={habdleReturnToAuthRegister}>
          {lang("去注册")}
        </Link>
      </div>
      <InstallAppDrawer />
    </div>
  );
};
export default memo(
  withGlobal((global): StateProps => {
    const { authError, authorizationLogOutFlage } = global;
    console.log("authError==", authError);

    return pick(global, [
      "authPhoneNumber",
      "authIsCodeViaApp",
      "authIsLoading",
      "authError",
      "authorizationLogOutFlage",
      "connectionState",
    ]);
  })(Login)
);
