import "../../global/actions/initial";

import type { FC } from "../../lib/teact/teact";
import React, { memo, useRef } from "../../lib/teact/teact";
import { getActions, withGlobal } from "../../global";

import type { GlobalState } from "../../global/types";

import { PLATFORM_ENV } from "../../util/windowEnvironment";

import useCurrentOrPrev from "../../hooks/useCurrentOrPrev";
import useElectronDrag from "../../hooks/useElectronDrag";
import useHistoryBack from "../../hooks/useHistoryBack";

import Transition from "../ui/Transition";
import AuthCode from "./AuthCode.async";
import AuthPassword from "./AuthPassword.async";
import AuthPhoneNumber from "./AuthPhoneNumber";
import Register from "./Register";
import Login from "./Login";
import AuthQrCode from "./AuthQrCode";
import AuthRegister from "./AuthRegister.async";
import Loading from '../ui/Loading';

import "./Auth.scss";

type StateProps = Pick<GlobalState, "authState">;

const Auth: FC<StateProps> = ({ authState }) => {
  
  const { returnToAuthPhoneNumber, goToAuthQrCode } = getActions();

  const isMobile = PLATFORM_ENV === "iOS" || PLATFORM_ENV === "Android";

  const handleChangeAuthorizationMethod = () => {
    if (!isMobile) {
      goToAuthQrCode();
    } else {
      returnToAuthPhoneNumber();
    }
  };

  useHistoryBack({
    isActive:
      (!isMobile && authState === "authorizationStateWaitPhoneNumber") ||
      (isMobile && authState === "authorizationStateWaitQrCode"),
    onBack: handleChangeAuthorizationMethod,
  });

  // eslint-disable-next-line no-null/no-null
  const containerRef = useRef<HTMLDivElement>(null);
  useElectronDrag(containerRef);

  // For animation purposes
  const renderingAuthState = useCurrentOrPrev(
    authState !== "authorizationStateReady" ? authState : undefined,
    true
  )||'authorizationStateWaitLogIn';

  function getScreen() {
    console.log("renderingAuthState==", renderingAuthState);
    // 路由控制
    switch (renderingAuthState) {
      case "authorizationStateWaitCode":
        return <AuthCode />;
      case "authorizationStateWaitPassword":
        return <AuthPassword />;
      // 注册页面
      case "authorizationStateWaitRegistration":
        return <AuthRegister />;
      case "authorizationStateWaitPhoneNumber":
        return <AuthPhoneNumber />;
      case "authorizationStateWaitQrCode":
        return <AuthQrCode /> //  null  // <AuthQrCode />;
      // 注册页面
      case "authorizationStateWaitRegister":
        return <Register />;
      // 登陆页面
      case "authorizationStateWaitLogIn":
        return <Login />;
      default:
        return <Loading /> //isMobile ? <AuthPhoneNumber /> : <AuthQrCode />;
    }
  }

  function getActiveKey() {
    //
    // authorizationStateWaitPhoneNumber

    switch (renderingAuthState) {
      case "authorizationStateWaitCode":
        return 0;
      case "authorizationStateWaitPassword":
        return 1;
      case "authorizationStateWaitRegistration":
        return 2;
      case "authorizationStateWaitPhoneNumber":
        return 3;
      case "authorizationStateWaitQrCode":
        return 4;
      case "authorizationStateWaitRegister":
        return 4;
      default:
        return isMobile ? 3 : 4;
    }
  }

  return (
    <Transition
      activeKey={getActiveKey()}
      name="fade"
      className="Auth"
      ref={containerRef}
    >
      {/* {null} */}
      {getScreen()}
    </Transition>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    return {
      authState: global.authState,
    };
  })(Auth)
);
