import type { ChangeEvent, FormEvent, RefObject } from "react";
import type { FC } from "../../lib/teact/teact";
import React, { useState, useMemo, memo } from "../../lib/teact/teact";

import buildClassName from "../../util/buildClassName";
import eyeIconPath from "../../assets/eye.svg";
import eyeClosedIconPath from "../../assets/eye-closed.svg";
import useLang from "../../hooks/useLang";
import "./InputText.scss";

type OwnProps = {
  ref?: RefObject<HTMLInputElement>;
  id?: string;
  className?: string;
  type?: string;
  value?: string;
  label?: string;
  error?: string;
  success?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  autoComplete?: string;
  maxLength?: number;
  tabIndex?: number;
  teactExperimentControlled?: boolean;
  inputMode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
};

const InputText: FC<OwnProps> = ({
  ref,
  id,
  className,
  value,
  label,
  error,
  success,
  disabled,
  readOnly,
  placeholder,
  autoComplete,
  inputMode,
  maxLength,
  tabIndex,
  teactExperimentControlled,
  onChange,
  onInput,
  onKeyPress,
  onKeyDown,
  onBlur,
  onPaste,
  type = "text",
}) => {
  const lang = useLang();
  const labelText = error || success || label;
  const fullClassName = buildClassName(
    "inputText-group",
    "input-group",
    value && "touched",
    error ? "error" : success && "success",
    disabled && "disabled",
    readOnly && "disabled",
    labelText && "with-label",
    className
  );
  const inputClassName = buildClassName(
    "form-control",
    type === "password" && "inputText"
  );
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = useMemo(() => {
    if (type === "password") {
      return showPassword ? "text" : "password";
    }
    return type;
  }, [type, showPassword]);

  return (
    <div className={fullClassName} dir={lang.isRtl ? "rtl" : undefined}>
      <input
        ref={ref}
        className={inputClassName}
        type={inputType}
        id={id}
        dir="auto"
        value={value || ""}
        tabIndex={tabIndex}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete={autoComplete}
        inputMode={inputMode}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onInput={onInput}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onPaste={onPaste}
        aria-label={labelText}
        teactExperimentControlled={teactExperimentControlled}
      />
      {labelText && <label htmlFor={id}>{labelText}</label>}
      {type === "password" && (
        <button
          className="passwordBtn"
          onClick={handleTogglePasswordVisibility}
        >
          {showPassword ? (
            <img
              className="eyeIcon"
              src={eyeIconPath}
              draggable={false}
              alt=""
            />
          ) : (
            <img
              className="eyeIcon"
              src={eyeClosedIconPath}
              draggable={false}
              alt=""
            />
          )}
        </button>
      )}
    </div>
  );
};

export default memo(InputText);
