import type { FC } from "src/lib/teact/teact";
import React, {
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo
} from "src/lib/teact/teact";
import { getActions, withGlobal } from "src/global";

import type {
    ApiCountryCode,
    ApiUser,
    ApiUserStatus,
} from "src/api/types";

import { selectUser, selectUserStatus } from "src/global/selectors";



import useLang from "src/hooks/useLang.ts";


import Button from "src/components/ui/Button";


import Modal from "src/components/ui/Modal";
import { clearStoredSession } from "src/util/sessions.ts";

import "./index.scss";


const ANIMATION_DURATION = 200;

export type OwnProps = {
    isOpen: boolean;
    userId?: string;
    isByPhoneNumber?: boolean;
    selectUser: Function;
};

type StateProps = {
    // isOpen: boolean;
    user?: ApiUser;
    userStatus?: ApiUserStatus;
    phoneCodeList: ApiCountryCode[];
    onCancel: Function;
    selectUser: Function;
    authorizationErrorMessage: string
};

const NewContactModal: FC<OwnProps & StateProps> = (props) => {
    const {
        isOpen,
        onCancel,
        unmarkIsShown,
        // authorizationErrorMessage
    } = props
    const [
        authorizationLogOutInfo,
        setAuthorizationLogOut
    ] = useState({})
    const {
        authorizationLogOutFlage,
        authorizationErrorMessage  
    }=authorizationLogOutInfo

    // const {
    //     authorizationLogOutFlage,
    //     authorizationErrorMessage
    // } = useMemo(() => {
    //     let erorInfo = sessionStorage.getItem('authorizationLogOut') || "{}"
    //     erorInfo = JSON.parse(erorInfo)
    //     return erorInfo
    // }, [])

    useEffect(() => {

        let erorInfo = sessionStorage.getItem('authorizationLogOut') || "{}"
        erorInfo = JSON.parse(erorInfo)

        setAuthorizationLogOut(erorInfo)


    }, [])

    const lang = useLang();

    return (
        <Modal
            className="AuthorizationLogOut Modal"
            contentClassName="NewContactContent"
            isOpen={authorizationLogOutFlage}
            title={lang("LogoutOptions.Title")}
            onClose={() => {
                sessionStorage.setItem('authorizationLogOut', JSON.stringify(
                    {
                    }
                ))
                setAuthorizationLogOut({})
            }}
            hasCloseButton
            onCloseAnimationEnd={unmarkIsShown}
        >
            <div>

                {authorizationErrorMessage}
            </div>

            <div className="dialog-buttons">
                <Button isText className="confirm-dialog-button"
                    onClick={() => {
   
                        sessionStorage.setItem('authorizationLogOut', JSON.stringify(
                            {
                            }
                        ))
                        setAuthorizationLogOut({})
                    }}>
                    {lang("Common.OK")}
                </Button>
                {/* <Button isText className="confirm-dialog-button" onClick={() => {
                    onCancel()
                }}>
                    {lang("Common.Cancel")}
                </Button> */}
            </div>
        </Modal>
    );
};

export default memo(
    withGlobal<OwnProps>((global, { userId }): StateProps => {
        const {
            authorizationErrorMessage
        } = global
        const user = userId ? selectUser(global, userId) : undefined;
        return {
            user,
            // isOpen,
            userStatus: userId ? selectUserStatus(global, userId) : undefined,
            phoneCodeList: global.countryList.phoneCodes,
            authorizationErrorMessage
        };
    })(NewContactModal)
);
