import React, { useState, useEffect } from "../../../lib/teact/teact";

// 高阶组件
const withInstallPrompt = (WrappedComponent: any) => {
  return (props: any) => {
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [isInstalling, setIsInstalling] = useState(false);

    useEffect(() => {
      const handleBeforeInstallPrompt = (e: any) => {
        e.preventDefault();
        setDeferredPrompt(e);
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      // 清理事件监听器
      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }, []);

    const handleInstallClick = async () => {
      if (!deferredPrompt) {
        return alert(
          "您已将 6-web 添加至桌面，如需重新添加，请删除或卸载当前桌面图标"
        );
      }
      setIsInstalling(true);
      try {
        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === "accepted") {
          console.log("用户接受了安装提示");
        } else {
          console.log("用户拒绝了安装提示");
        }
      } catch (error) {
        console.log("错误:", error);
      } finally {
        setDeferredPrompt(null);
        setIsInstalling(false);
      }
    };

    return (
      <WrappedComponent
        {...props}
        deferredPrompt={deferredPrompt}
        isInstalling={isInstalling}
        handleInstallClick={handleInstallClick}
      />
    );
  };
};

export default withInstallPrompt;
