import React, { useState, memo, useEffect } from "../../../lib/teact/teact";
import GuideDrawer from "./guide";
import { IS_IOS, IS_SAFARI } from "../../../util/windowEnvironment";
import buildClassName from "../../../util/buildClassName";
import Logo from "src/assets/logo.svg";
import InstallTips from "src/assets/icons/installApp/installTips.png";
import "./index.scss"; // 引入样式

import { selectTabState } from "../../../global/selectors";
import { getPromptInstall } from "../../../util/installPrompt";
import withInstallPrompt from "../withInstallPrompt";

const Drawer = (props: any) => {
  const { handleInstallClick } = props;
  const [isShow, setIsShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGuide, setIsOpenGuide] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const handleSwipe = (event: any) => {
    const { deltaY } = event;
    if (deltaY > 0) {
      setIsOpen(true); // 上滑手势展开抽屉
    } else if (deltaY < 0 && isOpen) {
      setIsOpen(false); // 下滑手势收起抽屉
    }
  };
  const addInstallHandle = () => {
    if (IS_IOS && IS_SAFARI) {
      setIsOpenGuide(true);
      return;
    }
    handleInstallClick();
  };
  if (!isShow) return;
  return (
    <div>
      <div
        className={buildClassName("drawer", isOpen && "open")}
        onWheel={handleSwipe}
      >
        <div className="drawer-trigger" onClick={toggleDrawer}>
          <div className="drawer-trigger-bar"></div>
        </div>
        <div className="drawer-content">
          <div className="drawer-content-top">
            <div className="drawer-content-top-left">
              <img className="drawer-logo" src={Logo} draggable={false} />
              <div>
                <div className="applyName">6-WEB</div>
                <div className="shortcuts">桌面快捷方式</div>
              </div>
            </div>
            <div className="drawer-content-top-right">
              <div
                className="noTips"
                onClick={() => {
                  setIsShow(false);
                }}
              >
                不再提示
              </div>
              <div className="installApp" onClick={addInstallHandle}>
                添加
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="drawer-content-bottom">
              <p className="moreTips">
                6
                是一款无手机号、无邮箱、无身份、无隐患、无状态。极简注册，隐私至上，安全无忧的通讯软件
              </p>
              <img
                className="installTipsImage"
                src={InstallTips}
                draggable={false}
              />
            </div>
          )}
        </div>
      </div>
      {isOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
      {isOpenGuide && (
        <GuideDrawer isOpen={isOpenGuide} setIsOpen={setIsOpenGuide} />
      )}
    </div>
  );
};

export default memo(withInstallPrompt(Drawer));
